
$theme-colors: (
  "primary": #26A65B
);


@import '~bootstrap/scss/bootstrap.scss';



.App {
  text-align: center;
}

.App .authButtons {
  float:right;
  padding: 20px;
}

.App .authButtons button {
  padding: 10px 15px;
  border: 0;
  margin-right: 5px;
  color: rgba(38, 166, 91, 1);
  font-weight: bold;
  
}

#root {
  width: 100vw;
  overflow: hidden;
}

.formContainer {
  display: block;
  width: 60%;
  margin: 0 auto;
  font-size: 24px;
  padding-top: 70px;
}

.formContainer form{
  width: 95%;
  margin: 0 auto;
}

.formContainer h1 {
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .reduce {
    display: none !important;
  }
  .formContainer {
    width: 100%;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: red;
  border-color: red;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: grey !important;
  box-shadow: none !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: grey;
}


.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone-preview {
  width: 100%;
}
